import { Container, Nav, Navbar, } from 'react-bootstrap';
import { BrowserRouter, Routes, Route, Link, } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';


// pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';

// assets
// import Logo from './assets/Logo/Logo2.png';


function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <header>
        <Navbar  variant="dark" expand="lg" className="bg-body-tertiary" className="custom-navbar">
          <Container>
            <Navbar.Brand as={Link} to="/">Swift Talents</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link as={Link} to="/">Home</Nav.Link>
              </Nav>
              <Nav className="ml-auto">
                <Nav.Link as={Link} to="contact">Contact</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

      </header>
      <main>
        <Routes>
          <Route index element={<Home />} />
          <Route path='contact' element={<Contact />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </main>
      <footer>
        <nav>

        </nav>
      </footer>
    </BrowserRouter>
  );
}

export default App;

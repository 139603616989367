import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"

export default function NotFound() {
    return (
        <div>
            <div className="not-found-div">
                <h2>Sorry, the page you are looking for cannot be found.</h2>
                <br></br>
                <p>It seems that the URL is incorrect or the link you followed may be outdated.</p>
                <p>Explore our Homepage by clicking the button below:</p>
                <Button variant="success" as={Link} to="/">Hompage</Button>{' '}               
            </div>
        </div>
    )
}
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div>
            <div className="footer-div">
                <p>
                    <Link to="/" className='footer-link'>Home</Link> |   
                    <Link to="/contact" className='footer-link'>Contact</Link>
                </p>
                
                <div className="copyright"><p>© 2024 Swift Talents</p></div>
            </div>
        </div>
    )
}
import { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import Submit from '../assets/Images/Contact.png';
import Footer from '../components/Footer';

export default function Contact() {
    // State variables for form data, validation errors, and submission status
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [errors, setErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);

    // Function to handle changes in form inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        // Reset validation error for the current field when it's being edited
        setErrors({ ...errors, [name]: '' });
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Perform form validation
        const validationErrors = {};
        if (!formData.name.trim()) {
            validationErrors.name = 'Name is required';
        }
        if (!formData.email.trim()) {
            validationErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            validationErrors.email = 'Invalid email format';
        }
        if (!formData.message.trim()) {
            validationErrors.message = 'Message is required';
        }
        // If there are validation errors, set the state and return
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        // If no validation errors, proceed with form submission
        try {
            const response = await fetch('https://swift-talents-node.vercel.app/api/contact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                // Handle successful form submission
                console.log('Form submitted successfully');
                setSubmitted(true);
                // Optionally, reset the form fields
                setFormData({
                    name: '',
                    email: '',
                    message: ''
                });
            } else {
                // Handle form submission error
                console.error('Form submission failed');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div>
            <div className="contact-div">
                <div className="section-card">
                    <div className="section-content">
                        <div className="about-image left">
                            <img className="about" src={Submit} alt="About Us" />
                        </div>
                        <div className="section-text">
                            <h2>Message me</h2>
                            <br></br>
                            
                            <Form onSubmit={handleSubmit}>
                                {/* Name input field */}
                                <Form.Group className="mb-3" controlId="name">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        maxLength={50}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                            handleChange(e);
                                            console.log(e.target.value)}}
                                        placeholder="Enter your name"
                                    />
                                    {/* Error message for name field */}
                                    {errors.name && <Form.Text className="text-danger">{errors.name}</Form.Text>}
                                </Form.Group>
                                {/* Email input field */}
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        maxLength={50}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                            handleChange(e);
                                            console.log(e.target.value)}}
                                        placeholder="name@example.com"
                                    />
                                    {/* Error message for email field */}
                                    {errors.email && <Form.Text className="text-danger">{errors.email}</Form.Text>}
                                </Form.Group>
                                {/* Message textarea field */}
                                <Form.Group className="mb-3" controlId="message">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="message"
                                        value={formData.message}
                                        maxLength={500}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                            handleChange(e);
                                            console.log(e.target.value)}}
                                    />
                                    {/* Error message for message field */}
                                    {errors.message && <Form.Text className="text-danger">{errors.message}</Form.Text>}
                                </Form.Group>
                                {/* Submit button */}
                                <Button variant="success" type="submit">Submit</Button>
                                {/* Success message */}
                                {submitted && <Form.Text className="text-success">Thank you for contacting. We'll get back to you shortly.</Form.Text>}
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

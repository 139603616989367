import About from '../assets/Images/About.png';
import Talents from '../assets/Images/Talents.png';
import Jobs from '../assets/Images/Jobs.png';
import { Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

export default function Home() {
    return (
        <div>
            <div className="home-div">
                <div className="section-card">
                    <div className="section-content">
                        <div className="about-image right">
                            <img className="about" src={ About } alt="About Us" />
                        </div>
                        <div className="section-text">
                            <h2>Welcome to Swift Talents!</h2>
                            <br></br>
                            <p>Hey there! I'm Keith and I'm thrilled to have you join me on this journey. With over six years of experience in international recruiting, I've had the chance to dive into various industries like IT, Construction, Banking, and Financing.</p> 
                            <p>It's been quite the ride, let me tell you! Swift Talents isn't just another average blog — it's a space where I pour out everything I've learned over the years. Whether you're an experienced pro looking to climb even higher in your career or someone just starting out and feeling a bit lost, you're in the right place.</p> 
                            <p>Here, you'll find all sorts of goodies: tips, tricks, and insider secrets to help you navigate the wild world of job hunting and recruitment. We'll talk about what's hot, what's not, and everything in between.</p> 
                            <p>So, buckle up and get ready for a ride. We're going to explore together, learn together, and hopefully, grow together. Welcome to Swift Talents, where the door is always open, and the coffee's always hot.</p>
                            <p>Cheers!</p>     
                               
                        </div>  
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}